import React from "react"

const SingleSideBySideRepeater = ({ singleSideBySideRepeater }) => {

  return (
    <div>
    {singleSideBySideRepeater.length ? (
      <section className="content">
    {singleSideBySideRepeater.map((prod,index) => (

                <div className="full-width-flex-container featured-cta-block sbs-repeater-one sbs-select" key={index}>

					<div className="full-half-flex-container">

							<div className="eighty-spacer"></div>

							<div className="featured-cta-text-container navy-text">
											<h4 className="staggerFadeIn stag-visible">
										{prod.singleSideBySideRepeaterTitle}
                    </h4>

										<h2 className="staggerFadeIn stag-visible">
										{prod.singleSideBySideRepeaterIntro}
                    </h2>

								<div className="featured-cta-content navy-text staggerFadeIn stag-visible" dangerouslySetInnerHTML={{ __html: prod.singleSideBySideRepeaterText }}/>
                {prod.singleSideBySideRepeaterButtonText ? (

										<a href={prod.singleSideBySideRepeaterLink}><div className="featured-cta-link-holder">
                    {prod.singleSideBySideRepeaterButtonText}
                    </div></a>

                  ): null}

							</div>
							<div className="show-on-responsive">
								<div className="eighty-spacer"></div>
							</div>


						</div>

            {prod.singleSideBySideRepeaterImage ? (
              <div className="full-half-flex-container image">
									<img src={`/htbcontent/uploads/${prod.singleSideBySideRepeaterImage.mediaDetails.file}`} alt={prod.singleSideBySideRepeaterImage.altText} title={prod.singleSideBySideRepeaterImage.title}></img>
								</div>) : null}

				</div>
      ))}
        </section>
      )
    : null}
    </div>
  )
  }
export default SingleSideBySideRepeater
