import React from "react"
import Slider from "react-slick"

import "../../../node_modules/slick-carousel/slick/slick.css"
import "../../../node_modules/slick-carousel/slick/slick-theme.css"

import blankbanner from "../../img/blank-banner-fix.png"

const slickSettings = {
  fade: true,
  autoplay: true,
  pauseOnHover: false,
  speed: 500,
  autoplaySpeed: 7000,
  accessibility: false,
  useCSS: true,
  swipe: true,
  swipeToSlide: true,
  arrows: false,
  dots: true,
  // customPaging: function(i) {
  //   return (
  //     <span></span>
  //   );
  // },
  responsive: [
    {
      breakpoint: 980,
      settings: {
        variableWidth: false,
        centerMode: false,
      },
    },
  ],
}

const HeroBanner = ({ bannerGroup,location, crumbLabel }) => {
  return (
    <section className="content">

    <Slider className="full-width-banner-short" {...slickSettings}>
      {bannerGroup.length
        ? bannerGroup.map((banners,index) => (
            <div key={index}>
              <div
                className="banner-short-image-holder hide-on-responsive"
                style={{
                  backgroundImage:
                    "url(/htbcontent/uploads/" +
                    banners.banner.featuredImage.mediaDetails.file +
                    ")",
                }}
              >
              <img
                src={blankbanner}
                alt={banners.banner.featuredImage.altText}
                title={banners.banner.featuredImage.title}
              ></img>
              </div>

              <div className="banner-short-image-holder-mob show-on-responsive">
                <img
                  src={`/htbcontent/uploads/${banners.banner.featuredImage.mediaDetails.file}`}
                  alt={banners.banner.featuredImage.altText}
                  title={banners.banner.featuredImage.title}
                ></img>
              </div>
              <div className="banner-outer-content-container">
                <div className="banner-inner-content-holder">
                <h5 className="slickStagger-0">
                  {banners.banner.bannerDetails.bannerSubTitle}{" "}
                </h5>
                <h4 className="slickStagger-0 slide-visible">
                                                <span>
                             {banners.banner.bannerDetails.statFigure}</span>
                          {banners.banner.bannerDetails.statSymbolAfter}</h4>
                <h3 className="slickStagger-0" dangerouslySetInnerHTML={{ __html: banners.banner.title}}/>
                  <div
                    className="slickStagger-0 hide-on-responsive"
                    dangerouslySetInnerHTML={{ __html: banners.banner.content }}
                  />
                  {banners.banner.bannerDetails.bannerUrl ? (
                  <div className="find-out-more slickStagger-0">
                    <a href={banners.banner.bannerDetails.bannerUrl}>
                      {banners.banner.bannerDetails.bannerLinkText}
                    </a>
                  </div>
                    ) : null}
                </div>
              </div>
            </div>
          ))
        : null}
    </Slider>
    </section>
  )
}

export default HeroBanner
