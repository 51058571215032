import React, { useState,useEffect } from "react"

function PageBody({ pageContent,pageTitle }){
  const pageUrl = typeof window !== 'undefined' ? window.location.pathname : '';
  const [url, setUrl] = useState(pageUrl);
  
  const updatePosts=(e)=>{
    var links = document.getElementsByTagName("a");
    for (var i = 0, l = links.length; i < l; i++) {
      var link = links[i];
      var link_text=link.innerHTML;
      if (link_text.indexOf("opt out") !== -1) {
        link.setAttribute("onClick", "CookieControl.open()");
      }
    }
  }

useEffect(() => {
  updatePosts()
}, []);



return (

  <section className={`page-body-section content fadeIn visible`}>
    <div className="eighty-spacer"></div>
    <div className="content-block">
      <div className="centered-content-container">
        <div className="centered-intro-title-holder">
        <h1>{pageTitle}</h1>
        </div>
        <div id="centered-links" className="centered-intro-text-holder no-bottom-pad" dangerouslySetInnerHTML={{ __html: pageContent }}/>
        </div>
      </div>
    <div className="eighty-spacer"></div>
  </section>
  ) 
}


export default PageBody
